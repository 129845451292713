import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    AssociatedCompanyModelDto,
    ChallengeGroupsClient,
    CompaniesClient,
    CompanyAccountSettingsModelDto,
    CompanyModelDto,
    LookupModelDto,
    ProductFeatureConfigurationsClient,
    ProductFeatureModelDto,
    SecureChallengeClient,
    TransferProductFeatureModelDto,
} from '@treasury/api/bo';
import { ChallengeGroup, Company, CompanyUserSummary } from './';
import * as ProductFeatures from './entities';
import { CompanyAccountSettings } from './entities/company-account-settings.entity';
import { CompanyAccount } from './entities/company-account.entity';
import { FeatureMappingType } from './types';
import { FeatureApiType } from './types/product-feature.enum';

const mapProductFeatureDto = (
    model: ProductFeatureModelDto,
    featureType: FeatureApiType
): ProductFeatures.CompanyProductFeature => {
    if (model.type !== featureType) {
        throw new Error(`Feature not implemented: ${model.type}`);
    }
    // TODO: As we implement the other features update the model passed to each constructor to be cast as the correct (tbd) DTO type
    switch (featureType) {
        case FeatureApiType.ACH:
            return new ProductFeatures.ACHProductFeature(model);
        case FeatureApiType.AccountRecon:
            return new ProductFeatures.AccountReconciliationReportingProductFeature(model);
        case FeatureApiType.Agiletics:
            return new ProductFeatures.AgileticsWebEscrowProductFeature(model);
        case FeatureApiType.BillPay:
            return new ProductFeatures.BillPayProductFeature(model);
        case FeatureApiType.ElectronicDocuments:
            return new ProductFeatures.ElectronicDocumentsProductFeature(model);
        case FeatureApiType.ForeignCurrencyWire:
            return new ProductFeatures.ForeignCurrencyWireProductFeature(model);
        case FeatureApiType.PositivePay:
            return new ProductFeatures.PositivePayProductFeature(model);
        case FeatureApiType.RemoteDeposit:
            return new ProductFeatures.RemoteDepositCaptureProductFeature(model);
        case FeatureApiType.Reporting:
            return new ProductFeatures.ReportingProductFeature(model);
        case FeatureApiType.StopPayment:
            return new ProductFeatures.StopPaymentProductFeature(model);
        case FeatureApiType.Transfer:
            return new ProductFeatures.TransferProductFeature(
                model as TransferProductFeatureModelDto
            );
        case FeatureApiType.Wausau:
            return new ProductFeatures.WausauEStatementsProductFeature(model);
        case FeatureApiType.Wire:
            return new ProductFeatures.WireProductFeature(model);
        default:
            throw new Error(`Feature not implemented: ${model.type}`);
    }
};

@Injectable()
export class CompanyService {
    constructor(
        private readonly companiesClient: CompaniesClient,
        private readonly productFeatureConfigurationsClient: ProductFeatureConfigurationsClient,
        private readonly challengeGroupsClient: ChallengeGroupsClient,
        private readonly secureChallengeClient: SecureChallengeClient
    ) {}

    /**
     * Retrieves a company by its ID and type.
     * @param companyId - The ID of the company.
     * @param companyType - The type of the company.
     * @returns A Promise that resolves to a Company object.
     */
    public async getCompanyById(companyId: number, companyType: number) {
        const companyResponse = await this.companiesClient.companiesGetOnboardedCompany(
            companyId,
            companyType
        );

        const challengeGroups = await this.getAllChallengeGroups();

        return new Company(companyResponse.data, challengeGroups);
    }

    /**
     * Updates the unique identifier of a company.
     *
     * @param companyId - The ID of the company to update.
     * @param companyUniqueId - The new unique identifier for the company.
     * @returns A promise that resolves to an instance of the updated Company.
     */
    public async updateCompanyUniqueId(companyId: number, companyUniqueId: string) {
        const response = await this.companiesClient.companiesUpdateCompanyUniqueId(
            companyId,
            companyUniqueId
        );

        return new Company(response.data);
    }

    public async updateCompanyAuthProfile(companyId: number, authProfileId: number) {
        const response = await this.secureChallengeClient.secureChallengeUpdateChallengeGroup({
            companyId,
            challengeGroupId: authProfileId,
        });

        return response.data;
    }

    /**
     * Updates the authentication status of a company.
     *
     * @param companyId - The unique identifier of the company.
     * @param authEnabled - A boolean indicating whether authentication is enabled or not.
     * @returns A promise that resolves to the response data from the secure challenge client.
     */
    public async updateCompanyAuthStatus(companyId: number, authEnabled: boolean) {
        const response = await this.secureChallengeClient.secureChallengeUpdateStatus({
            companyId,
            isSecureChallengeEnabled: authEnabled,
        });
        return response.data;
    }

    /**
     * Updates the authentication method for a specified company.
     *
     * @param companyId - The unique identifier of the company.
     * @param authMethod - The new authentication method to be set for the company.
     * @returns A promise that resolves to the response data from the secure challenge client.
     */
    public async updateCompanyAuthMethod(companyId: number, authMethod: number) {
        const response = await this.secureChallengeClient.secureChallengeUpdateChallengeMethodType({
            companyId,
            challengeMethodType: authMethod,
        });
        return response.data;
    }

    /**
     * Retrieves all challenge groups.
     * @returns {Promise<ChallengeGroup[]>} A promise that resolves to an array of ChallengeGroup objects.
     */
    public async getAllChallengeGroups() {
        const challengeGroups = await this.challengeGroupsClient.challengeGroupsGetAll();
        return challengeGroups.data.map(cg => new ChallengeGroup(cg));
    }

    async getCompanyAccounts(companyId: number) {
        const companyIdString = companyId.toString();
        const accounts = (await this.companiesClient.companiesAccountsAllGet(companyIdString)).data;
        return accounts.map(account => new CompanyAccount(account));
    }

    async getUsers(companyId: number) {
        const users = (await this.companiesClient.companiesUsersAll(companyId, false)).data;
        return users.map(user => new CompanyUserSummary(user));
    }

    async updateCompanyStatus(company: Company) {
        const dto = company.toDto();
        const companyIdString = dto.id.toString();
        const response = await this.companiesClient.companiesStatus(companyIdString, dto);

        return response.data;
    }

    async updateCompanyAccountStatus(companyId: number, accountId: number, active: boolean) {
        return this.companiesClient.companiesAccountStatus(
            companyId,
            accountId,
            active ? 'Active' : 'Inactive'
        );
    }

    async getCompanyAvailableProductFeatures(companyId: number) {
        const features = (
            await this.companiesClient.companiesGetAvailableProductsForCompany(companyId)
        ).data;
        return features.map(feature => new ProductFeatures.FiProductFeature(feature));
    }

    async getCompanyProductFeatureByName(featureName: string) {
        const feature = new ProductFeatures.FiProductFeature(
            (
                await this.productFeatureConfigurationsClient.productFeatureConfigurationsGetByName(
                    featureName
                )
            ).data
        );
        return feature;
    }

    async getCompanyProductFeatures(companyId: number) {
        const companyIdString = companyId.toString();
        const features = (await this.companiesClient.companiesProductFeaturesAll(companyIdString))
            .data;
        return features.map(feature => new ProductFeatures.CompanyProductFeature(feature));
    }

    async getCompanyProductFeature<T extends keyof FeatureMappingType>(
        companyId: number,
        featureId: number,
        featureType: T
    ) {
        const feature = (
            await this.companiesClient.companiesProductFeaturesGet(companyId, featureId)
        ).data;

        return mapProductFeatureDto(feature, featureType) as FeatureMappingType[T];
    }

    async updateProductFeature(
        companyId: number,
        featureId: number,
        productFeature: ProductFeatures.CompanyProductFeature
    ) {
        const result = (
            await this.companiesClient.companiesProductFeaturesPut(
                companyId,
                featureId,
                productFeature.toDto()
            )
        ).data;
        return new ProductFeatures.CompanyProductFeature(result);
    }

    async updateTransferProductFeature(
        companyId: number,
        featureId: number,
        productFeature: ProductFeatures.TransferProductFeature
    ) {
        const result = (
            await this.companiesClient.companiesProductFeaturesPut(
                companyId,
                featureId,
                productFeature.toDto()
            )
        ).data as TransferProductFeatureModelDto;
        return new ProductFeatures.TransferProductFeature(result);
    }

    async saveTransferProductFeature(
        companyId: number,
        productFeature: ProductFeatures.TransferProductFeature
    ) {
        const result = (
            await this.companiesClient.companiesProductFeaturesPost(
                `${companyId}`,
                productFeature.toDto()
            )
        ).data as TransferProductFeatureModelDto;
        return new ProductFeatures.TransferProductFeature(result);
    }

    async getCompanyUserAccountSettings(companyId: number): Promise<CompanyAccountSettings> {
        return new CompanyAccountSettings(
            (await this.companiesClient.companiesUserAccountSettingsGet(companyId)).data
        );
    }

    async saveCompanyUserAccountSettings(
        companyId: number,
        dto: CompanyAccountSettingsModelDto
    ): Promise<CompanyAccountSettings> {
        return new CompanyAccountSettings(
            (await this.companiesClient.companiesUserAccountSettingsPut(companyId, dto)).data
        );
    }

    async getAssociatedCompanies(companyId: number): Promise<ProductFeatures.AssociatedCompany[]> {
        return (await this.companiesClient.companiesAssociatedCompaniesAll(companyId)).data.map(
            (x: AssociatedCompanyModelDto) => new ProductFeatures.AssociatedCompany(x)
        );
    }

    async searchNonOnboardedCompanies(searchValue: string): Promise<Company[]> {
        const companyNameSearch = async () =>
            await this.companiesClient.companiesNonOnboardedCompanies({
                searchType: 'Company Name',
                searchValue,
            });

        const taxIdNumberSearch = /^\d+$/.test(searchValue)
            ? async () =>
                  await this.companiesClient.companiesNonOnboardedCompanies({
                      searchType: 'TAX ID Number',
                      searchValue,
                  })
            : () => Promise.resolve({ data: [] as CompanyModelDto[] });

        const cifNumberSearch = async () =>
            await this.companiesClient.companiesNonOnboardedCompanies({
                searchType: 'CIF Number',
                searchValue,
            });

        return (await Promise.all([cifNumberSearch(), companyNameSearch(), taxIdNumberSearch()]))
            .flatMap(x => x.data)
            .map(x => new Company(x));
    }

    async addAssociatedCompany(companyId: number, cifNumber?: string) {
        if (cifNumber) {
            return this.companiesClient.companiesAssociatedCompanies(companyId, {
                value: cifNumber,
            } as unknown as LookupModelDto);
        }
    }

    async changeProductStatus(companyId: number, productId: number, status: string) {
        const companyIdString = companyId.toString();
        const productIdString = productId.toString();
        return (
            await this.companiesClient.companiesProductStatus(companyIdString, productIdString, {
                status,
            })
        ).data;
    }
}
